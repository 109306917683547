<template>
  <div class="contBox fatherHeight">
    <div class="temp-tit flex-between">
      <div class="title">修改排床模板 第132周（双周）</div>
      <div class="serach-right">
        <el-button class="color-main" @click="back" type="primary">
          返回
        </el-button>
      </div>
    </div>

    <div class="bodyrodoi flex-between">
      <div class="dialysisType flex-between">
        <div
          class="rodoi-left"
          :class="dialysisTypeLeft == 0 ? 'default' : ''"
          v-if="isdialysisItem"
          @click="dialysisTypeBut('left')"
        >
          <i class="fa fa-angle-left"></i>
        </div>
        <div class="bodyrodoiBox" ref="dialysisTypeRef">
          <div
            ref="dialysisItemRef"
            class="flex-start bodyrodoiBoxItem"
            :style="{
              left: dialysisTypeLeft + 'px',
            }"
          >
            <div v-for="item in dictList[100000000]" :key="item.code">
              <div
                class="radios"
                :class="[actDialyse.code === item.code ? 'active' : '']"
                @click="rodeiclis(item)"
              >
                {{ item.name }}
              </div>
            </div>
          </div>
        </div>
        <div
          class="rodoi-right"
          :class="dialysisTypeLeft == -dialysisTypeWin ? 'default' : ''"
          v-if="isdialysisItem"
          @click="dialysisTypeBut('right')"
        >
          <i class="fa fa-angle-right"></i>
        </div>
      </div>
      <div class="ruboy">
        <div style="width: 40%">您已选择:</div>
        <div style="width: 60%">{{ actDialyse.name }}</div>
      </div>
    </div>

    <div class="appointmentCont flex-start">
      <div class="patientList">
        <el-input
          v-model="searchData.search_code"
          class="w-50 m-2"
          placeholder="搜索"
        >
          <template #suffix>
            <el-icon class="el-input__icon" @click="getPatientList">
              <search />
            </el-icon>
          </template>
        </el-input>
        <div class="lifclisBox">
          <el-scrollbar>
            <div
              class="lifclis"
              :class="[actPatient.id === items.id ? 'active' : '']"
              v-for="items in Nlist"
              :key="items.id"
              @click="Bhzclic(items)"
            >
              <template v-if="items.name">
                <div style="display: flex; align-items: center">
                  <!-- 鼠标移入显示病患信息 -->
                  <div>
                    <el-popover placement="right" :width="220" trigger="hover">
                      <template #reference>
                        <div v-if="items.infect.length > 0" class="Fclis">
                          {{ items.infect[0].dict_disease_name }}
                        </div>
                      </template>
                      <div style="display: flex">
                        <div>姓名：</div>
                        <div>{{ items.name }}</div>
                      </div>
                      <div style="display: flex; margin: 2px 0">
                        <div>性别：</div>
                        <div>{{ items.dict_sex_name }}</div>
                      </div>
                      <div style="display: flex; margin: 2px 0">
                        <div>病历号：</div>
                        <div v-if="items.his">{{ items.his.case_no }}</div>
                      </div>
                      <div style="display: flex">
                        <div>感染类型：</div>
                        <div
                          v-for="(rows, indexs) in items.infect"
                          :key="indexs"
                          style="margin: 0 2px"
                        >
                          {{ rows.dict_disease_name }}
                        </div>
                      </div>
                    </el-popover>
                  </div>
                  <div class="nacliss">
                    {{ items.name }}
                    <span style="font-weight: 400; color: #70d240">
                      ({{ items.age }})</span
                    >
                  </div>
                </div>
                <div>
                  <div :class="[items.bed.length > 0 ? 'numbcls' : 'numbclss']">
                    {{ items.bed.length }}
                  </div>
                </div>
              </template>
            </div>
          </el-scrollbar>
        </div>
      </div>
      <div class="cont-table">
        <div class="table" ref="tableRef">
          <el-table
            :data="tableData"
            v-loading="dataTableLoading"
            :span-method="arraySpanMethod"
            row-key="id"
            size="small"
            :header-cell-style="{ textAlign: 'center', borderColor: '#fff' }"
            :cell-style="{ textAlign: 'center' }"
            max-height="500"
            border
          >
            <el-table-column prop="name" label="组号" width="80" />
            <el-table-column label="床号" width="80">
              <template #default="scope">
                <el-tooltip placement="right" effect="light">
                  <template #content>
                    <div class="tooltipCont">
                      <div>床位号：{{ scope.row.bed_no }}</div>
                      <div>感染类型：{{ scope.row.dict_infect_flag_name }}</div>
                      <div v-if="scope.row.machine">
                        治疗模式：{{
                          scope.row.machine.dict_treat_model_name.join(' ')
                        }}
                      </div>
                    </div>
                  </template>
                  <div>
                    <div class="bedName bedcul">{{ scope.row.bed_no }}</div>
                    <!-- <div class="flex-between plain">
                        <el-tag
                          class="mx-1"
                          v-if="scope.row.dict_infect_flag_name"
                          type="danger"
                          effect="dark"
                        >
                          {{ scope.row.dict_infect_flag_name }}
                        </el-tag>
                        <el-tag class="mx-1" effect="plain">
                          {{ scope.row.machine.dict_treat_model_name[0] }}
                        </el-tag>
                      </div> -->
                  </div>
                </el-tooltip>
              </template>
            </el-table-column>
            <el-table-column v-for="(item, index) in weekList" :key="index">
              <template #header> {{ item.week }} {{ item.date }} </template>
              <el-table-column
                v-for="item2 in item.sun"
                :key="item2.id"
                :label="item2.name"
                width="80"
              >
                <el-table-column width="80" :column-key="item2.id">
                  <template #header>
                    <div class="bedSubtotal" v-if="bedSubtotal[item2.id]">
                      <el-tooltip placement="top" effect="light">
                        <template #content>
                          <div class="tooltipCont">
                            <div>
                              排床小计 - {{ item2.week }}{{ item2.name }}
                            </div>
                            <div>
                              总床位数： {{ bedSubtotal[item2.id].total }} /
                              {{ tableData.length }}
                            </div>
                            <div
                              class="item"
                              v-for="(item3, index3) in bedSubtotal[item2.id]
                                .sun"
                              :key="index3"
                            >
                              {{ index3 }}:{{ item3 }}
                            </div>
                          </div>
                        </template>
                        <div class="num">
                          {{ bedSubtotal[item2.id].total }}/{{
                            tableData.length
                          }}
                        </div>
                      </el-tooltip>
                    </div>
                    <div class="bedSubtotal" v-else>
                      0/{{ tableData.length }}
                    </div>
                  </template>
                  <template #default="scope">
                    <div
                      :draggable="isDrag"
                      class="thead-cell"
                      :class="
                        scope.row[item2.id] &&
                        scope.row[item2.id].patient_id == actPatient.id
                          ? 'active'
                          : ''
                      "
                      v-if="scope.row[item2.id]"
                      @click="handleClick(scope.row, item2)"
                    >
                      <i
                        class="fa fa-times-circle"
                        @click.prevent.stop="delBedItem(scope.row[item2.id].id)"
                      ></i>
                      <div class="patientName">
                        {{ scope.row[item2.id].patient_name }}
                      </div>
                      {{ scope.row[item2.id].nm_dialyse_way }}
                    </div>
                    <div
                      class="thead-cell"
                      v-else
                      @click="handleClick(scope.row, item2)"
                    ></div>
                  </template>
                </el-table-column>
              </el-table-column>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  reactive,
  toRefs,
  onMounted,
  watch,
  onBeforeUnmount,
  computed,
  nextTick,
} from 'vue'
import { Search } from '@element-plus/icons-vue'
import { useStore } from 'vuex'
import { ElMessage, ElMessageBox } from 'element-plus'
import { gitDictList } from '@/utils/tool'
import service from '@/utils/request'
export default {
  components: {
    Search,
  },
  directives: {
    print,
  },
  setup() {
    const userInfo = localStorage.getItem('ms_userData')
    const state = reactive({
      searchData: {
        week: '',
      },
      tableSearch: {
        week: '',
        hospital_area_id: userInfo.hospital,
      },
      pageData: {
        currentPage: 1,
        pageSize: 10,
        totalSum: 0,
      },
      dataListLoading: false,
      tableRef: null,
      bleData: [],
      tableData: [],
      dictList: {},
      Nlist: [],
      hospitalList: [],
      weekList: [],
      bedList: [],
      actDialyse: {},
      actPatient: {},
      dataTableLoading: false,
      visibleRepeat: false,
      repeatMsg: '',
      title: '',
      addBedParam: {},
      bedSubtotal: {},
      dialysisTypeRef: null,
      dialysisItemRef: null,
      isdialysisItem: true,
      dialysisTypeWin: 0,
      dialysisTypeLeft: 0,
    })

    const store = useStore()
    const collapse = computed(() => store.state.collapse)
    watch(collapse, () => {
      setTimeout(() => {
        dialysisTypeInit()
      }, 300)
    })
    const dialysisTypeInit = () => {
      let typeWin = state.dialysisTypeRef.clientWidth
      let itemWin = state.dialysisItemRef.clientWidth
      if (itemWin > typeWin) {
        state.isdialysisItem = true
        state.dialysisTypeWin = itemWin - typeWin
      } else {
        state.isdialysisItem = false
        state.dialysisTypeWin = 0
      }
      // console.log(state.dialysisTypeWin)
    }
    const dialysisTypeBut = (type) => {
      if (!state.dialysisTypeWin) return
      if (type === 'left') {
        if (state.dialysisTypeLeft === 0) return
        if (state.dialysisTypeLeft < -200) {
          state.dialysisTypeLeft += 200
        } else {
          state.dialysisTypeLeft = 0
        }
      } else {
        const par = state.dialysisTypeLeft * -1

        if (par === state.dialysisTypeWin) return
        if (par + 200 < state.dialysisTypeWin) {
          state.dialysisTypeLeft += -200
        } else {
          state.dialysisTypeLeft = state.dialysisTypeWin * -1
        }
        // console.log(state.dialysisTypeLeft)
      }
    }
    onMounted(() => {
      initData()
      window.addEventListener('resize', dialysisTypeInit)
    })
    onBeforeUnmount(() => {
      window.removeEventListener('resize', dialysisTypeInit)
    })

    const initData = async () => {
      gitDict() // 字典数据
      getHospitalList()
      await getWeek()
      await getPatientList()
      getMOnTOSun()
      await getBedList()
      getDataList()
    }
    const leftBeds = (type) => {
      if (type === 'add') {
        state.searchData.week++
        state.tableSearch.week++
      } else {
        state.searchData.week--
        state.tableSearch.week--
      }
      getMOnTOSun()
      getDataList()
    }
    const gitDict = async () => {
      state.dictList = await gitDictList(['100000000'])
      nextTick(() => {
        dialysisTypeInit()
      })
      if (state.dictList[100000000].length > 0) {
        state.actDialyse = state.dictList[100000000][0]
      }
    }
    const getHospitalList = async () => {
      let res = await service.post('/api/hospital/list', {
        parent_id: 0,
      })
      if (res.code === 0) {
        state.hospitalList = res.data
        if (!state.tableSearch.hospital_area_id) {
          state.tableSearch.hospital_area_id = res.data[0].id
          state.tableSearch.hospital_area_name = res.data[0].name
        }
      }
    }
    const getWeek = async () => {
      let res = await service.post('/api/week/get_week')
      if (res.code === 0) {
        state.searchData.week = res.data.week
        state.tableSearch.week = res.data.week
      }
    }
    const getMOnTOSun = async () => {
      let res = await service.post('/api/week/mon_to_sun', {
        week: state.searchData.week,
      })
      if (res.code === 0) {
        res.data.map((item, index) => {
          let par = [
            {
              name: '上午',
              id: index + ',1',
              week: item.week,
              date: item.date,
            },
            {
              name: '下午',
              id: index + ',2',
              week: item.week,
              date: item.date,
            },
            {
              name: '晚上',
              id: index + ',3',
              week: item.week,
              date: item.date,
            },
          ]
          item.sun = par
        })
        state.weekList = res.data
      }
    }
    const getBedList = async () => {
      let res = await service.post('/api/schedule_beds/bed', {
        hospital_area_id: state.tableSearch.hospital_area_id,
      })
      if (res.code === 0) {
        state.bedList = res.data
        gitTableList()
      }
    }
    const gitTableList = () => {
      let row = []
      state.bedList.map((item) => {
        item.uID = item.id
        item.bed.map((item2, index2) => {
          let param = {
            ...item,
            ...item2,
          }
          if (index2 === 0) {
            param.column = item.bed.length
          }
          row.push(param)
        })
      })
      state.bleData = row
    }
    const getPatientList = async () => {
      state.dataListLoading = true
      let res = await service.post(
        '/api/schedule_beds/patient_list',
        state.searchData
      )
      state.dataListLoading = false
      if (res.code === 0) {
        state.Nlist = res.data
        if (res.data.length > 0 && !state.actPatient.id) {
          state.actPatient = res.data[0]
        }
      }
    }
    const getDataList = async () => {
      state.dataTableLoading = true
      let res = await service.post('/api/schedule_beds/list', state.tableSearch)
      state.dataTableLoading = false
      if (res.code === 0) {
        getTableList(res.data)
      }
    }
    const arraySpanMethod = ({ row, columnIndex }) => {
      if (columnIndex === 0) {
        if (row.column) {
          return {
            rowspan: row.column,
            colspan: 1,
          }
        } else {
          return {
            rowspan: 0,
            colspan: 0,
          }
        }
      }
    }

    const hospitalChe = async (val) => {
      state.hospitalList.map((item) => {
        if (item.id === val) {
          state.tableSearch.hospital_area_name = item.name
        }
      })
      await getPatientList()
      await getBedList()
      getDataList()
    }

    const getTableList = (row) => {
      state.bedSubtotal = {}
      gitTableList()
      row.map((item) => {
        let index = getColumKey(item)
        state.bleData.map((item2) => {
          if (item.bed_num === item2.bed_no) {
            item2[index] = item
            if (
              !Object.prototype.hasOwnProperty.call(state.bedSubtotal, index)
            ) {
              state.bedSubtotal[index] = {
                total: 0,
                sun: {},
              }
            }
            state.bedSubtotal[index].total++
            getDialyseNum(state.bedSubtotal[index].sun, item.nm_dialyse_way)
          }
        })
      })
      // console.log(state.bleData)
      state.tableData = state.bleData
    }
    const getDialyseNum = (row, val) => {
      if (Object.prototype.hasOwnProperty.call(row, val)) {
        row[val]++
      } else {
        row[val] = 1
      }
    }
    // 获取列key
    const getColumKey = (item) => {
      let par = []
      switch (item.weekday) {
        case '周一':
          par[0] = 0
          break
        case '周二':
          par[0] = 1
          break
        case '周三':
          par[0] = 2
          break
        case '周四':
          par[0] = 3
          break
        case '周五':
          par[0] = 4
          break
        case '周六':
          par[0] = 5
          break
        case '周日':
          par[0] = 6
          break
      }
      switch (item.nm_name) {
        case '上午':
          par[1] = 1
          break
        case '下午':
          par[1] = 2
          break
        case '晚上':
          par[1] = 3
          break
      }
      return par.join(',')
    }
    // 表格点击
    const handleClick = (row, item) => {
      if (row[item.id]) {
        return
      }
      if (!state.actDialyse.code) {
        ElMessage({
          message: '请先选择透析方式',
          type: 'warning',
        })
        return
      }
      if (!state.actPatient.id) {
        ElMessage({
          message: '请先选择患者',
          type: 'warning',
        })
        return
      }
      addBeds(row, item)
    }

    // 添加排床
    const addBeds = async (row, item, type) => {
      let param = {}
      // type 当天是否有排床 true 有
      if (!type) {
        param = {
          bed_num: row.bed_no,
          dict_dialyse_way: state.actDialyse.code,
          nm_dialyse_way: state.actDialyse.name,
          dict_group_name: row.uID,
          nm_group_name: row.name,
          hospital_area_id: state.tableSearch.hospital_area_id,
          hospital_area_name: state.tableSearch.hospital_area_name,
          nm_name: item.name,
          patient_id: state.actPatient.id,
          schedule_date: item.date,
          week: item.week,
        }
        state.addBedParam = param
      } else {
        param = row
        param.other = type
      }

      let res = await service.post('/api/schedule_beds/create', param)
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
        getPatientList()
      } else if (res.code === -66) {
        state.title = res.msg
        state.visibleRepeat = true
      }
    }

    const delBedItem = (id) => {
      ElMessageBox.confirm('是否确定删除该数据?', '操作提示', {
        confirmButtonText: '确认',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          delBeds(id)
        })
        .catch(() => {})
    }
    const delBeds = async (id) => {
      let res = await service.post('/api/schedule_beds/delete', {
        id: id,
      })
      if (res.code === 0) {
        ElMessage.success(res.msg)
        getDataList()
        getPatientList()
      }
    }
    const accessItem = (type) => {
      addBeds(state.addBedParam, '', type)
      state.visibleRepeat = false
    }

    const rodeiclis = (item) => {
      state.actDialyse = item
    }
    const Bhzclic = (item) => {
      state.actPatient = item
    }

    return {
      ...toRefs(state),
      rodeiclis,
      Bhzclic,
      arraySpanMethod,
      handleClick,
      delBedItem,
      leftBeds,
      hospitalChe,
      getPatientList,
      accessItem,
      dialysisTypeInit,
      dialysisTypeBut,
    }
  },
}
</script>

<style scoped lang="scss">
.appointmentCont {
  height: calc(100% - 40px);
}
.temp-tit {
  .title {
    font-size: 22px;
    color: #000000;
  }
}
.el-button {
  margin-left: 8px;
}
.contextmenu {
  margin: 0;
  background: #fff;
  z-index: 3000;
  width: 86px;
  position: absolute;
  list-style-type: none;
  padding: 5px 0;
  border-radius: 4px;
  font-size: 13px;
  font-weight: 400;
  color: #333;
  box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
  border: 1px solid rgb(238, 238, 238);
  li {
    margin: 0;
    padding: 7px 16px;
    width: 90px;
    cursor: pointer;
    color: #666;
    &:hover {
      color: #3166ae;
      .contextmenuItem {
        display: block;
      }
    }
    .contextmenuItem {
      display: none;
      list-style-type: none;
      position: absolute;
      bottom: -2px;
      left: 90px;
      background: #fff;
      box-shadow: 2px 2px 3px 0 rgba(0, 0, 0, 0.3);
      border: 1px solid rgb(238, 238, 238);
      z-index: 3001;
      li {
        padding: 6px 16px;
        width: 140px;
        &:hover {
          background: #eee;
          color: #3166ae;
        }
      }
    }
  }
}
.lifclisBox {
  height: calc(100% - 72px);
  margin-top: 10px;
  overflow: hidden;
  overflow-y: auto;
}
.bedSubtotal {
  // height: 42px;
  // line-height: 42px;
}
.tongluBox {
  text-align: center;
  margin: 40px 0;
  color: #666666;
}
.patientList {
  width: 216px;
  margin-right: 10px;
}
.tooltipCont {
  padding: 10px;
  padding-bottom: 2px;
  > div {
    margin-bottom: 8px;
  }
}
// .patientName {
//   margin-bottom: 4px;
// }
.cont-table {
  width: calc(100% - 226px);
  overflow: hidden;
  .table {
    margin-top: 42px;
    user-select: none;
    position: relative;
    .el-table {
      height: calc(100% - 172px);
    }
    .thead-cell {
      padding: 2px;
      min-height: 48px;
      cursor: pointer;
      .fa-times-circle {
        color: red;
        position: absolute;
        top: 1px;
        right: 1px;
        font-size: 18px;
        cursor: pointer;
        display: none;
      }
      &:hover {
        background-color: rgba(49, 102, 174, 0.3);
        .fa-times-circle {
          display: inline-block;
        }
      }
      &.active {
        background-color: rgb(49, 102, 174, 0.5);
      }
    }
    .bedcul {
      background-color: rgba(234, 239, 247);
      border-bottom: 1px solid;
      border-color: #fff;
      min-height: 48px;
      line-height: 48px;
    }
    .plain {
      margin-bottom: 4px;
    }
    &:deep(.el-table) {
      tr:hover,
      .el-table__body tr:hover > td.el-table__cell {
        background-color: #ffffff;
        cursor: initial;
      }
      .el-table__body tr > td.el-table__cell {
        padding: 0;
        .cell {
          padding: 0;
        }
      }
      .el-table__body tr > td.el-table__cell:nth-child(1) {
        background-color: rgba(234, 239, 247);
        border-color: #fff;
        .cell {
          padding: 0 12px;
        }
      }
    }
  }
}
.el-form {
  margin-top: 0;
}
.border {
  height: 56px;
  background-color: #fff;
  border-radius: 8px;
  padding: 2px 10px;
  display: flex;
  justify-content: space-between;
}
.btnr {
  display: flex;
  align-items: center;
  height: 32px;
}
.bodey {
  background: #ffffff;
  border-radius: 8px;
  padding: 10px;
  height: 100%;
}
.bodyrodoi {
  display: flex;
  justify-content: space-between;
  margin: 8px 0;
  .rodoi-left,
  .rodoi-right {
    width: 32px;
    height: 32px;
    background-color: #fff;
    cursor: pointer;
    z-index: 1;
    display: flex;
    justify-items: center;
    align-items: center;
    &.default {
      cursor: not-allowed;
    }
    .fa {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      border: 1px solid rgba(0, 0, 0, 0.24);
      text-align: center;
      color: #3166ae;
      font-size: 24px;
    }
  }
  .dialysisType {
    flex: 1;
    overflow: hidden;
    .bodyrodoiBox {
      width: calc(100% - 64px);
      position: relative;
      .bodyrodoiBoxItem {
        transition: left 0.3s;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
  }
}
.radios {
  height: 32px;
  line-height: 32px;
  background: #fff;
  border-radius: 4px;
  text-align: center;
  padding: 0 10px;
  color: #3166ae;
  margin-right: 8px;
  border: 1px solid rgba(49, 102, 174, 1);
  cursor: pointer;
  white-space: nowrap;
  &.active {
    background: #3166ae;
    color: #fff;
  }
}
.ruboy {
  text-align: center;
  background-color: rgba(49, 102, 174, 1);
  color: #fff;
  border-radius: 4px;
  display: flex;
  min-width: 200px;
  height: 32px;
  line-height: 32px;
  justify-content: space-around;
  font-size: 14px;
}
.searchass {
  font-size: 25px;
  margin: 8px;
  cursor: pointer;
  color: rgba(153, 153, 153, 1);
}
.textclas {
  color: rgba(49, 102, 174, 1);
  font-size: 18px;
  text-align: center;
  height: 45px;
  line-height: 45px;
  margin-left: 10px;
}
.lifclis {
  height: 32px;
  line-height: 32px;
  border-radius: 4px;
  opacity: 1;
  display: flex;
  padding: 0 10px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  &.active {
    background: rgba(49, 102, 174, 0.3);
  }
  &:hover {
    background: rgba(49, 102, 174, 0.1);
  }
}
.Fclis {
  // width: 44px;
  padding: 0 8px;
  height: 24px;
  background-color: rgba(217, 236, 255, 1);
  border-radius: 6px;
  opacity: 1;
  font-size: 12px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: rgba(64, 158, 255, 1);
  line-height: 24px;
  font-size: 12px;
  text-align: center;
  margin-right: 5px;
}
.nacliss {
  color: rgba(49, 102, 174, 1);
  // width: 80px;
  // text-align: center;
  font-size: 14px;
}
.numbclss {
  width: 18px;
  height: 18px;
  line-height: 18px;
  background: rgba(255, 62, 62, 0.1);
  font-size: 12px;
  color: rgba(255, 62, 62, 1);
  border-radius: 18px;
  text-align: center;
}
.numbcls {
  width: 18px;
  height: 18px;
  line-height: 18px;
  background: rgba(49, 102, 174, 0.3);
  font-size: 12px;
  color: rgba(49, 102, 174, 1);
  border-radius: 18px;
  text-align: center;
}
.el-button--text {
  margin-right: 15px;
}
.el-select {
  width: 300px;
}
.iconcliss {
  width: 32px;
  height: 32px;
  background: #3166ae;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  color: #fff;
  text-align: center;
  line-height: 32px;
  cursor: pointer;
  font-size: 18px;
}
.textclss {
  font-size: 18px;
  width: 18px;
  height: 32px;
  font-family: Source Han Sans CN-Regular, Source Han Sans CN;
  font-weight: 400;
  color: #444444;
  line-height: 32px;
}
</style>
